import Fetch from '@/utils/fetch';
// import Configs from '@/config';

export const getStatus = (params) =>
    Fetch({
        url: '/api/inneruserproject/v001/customer/queryStatus',
        params,
    });

export const cancelAccount = (params) =>
    Fetch({
        url: '/api/inneruserproject/v001/customer/cancel',
        params,
    });
