<template>
    <div v-if="loaded">
        <section
            v-if="+delFlag === 0 && +signFlag === 0"
            style="font-size: 14px"
            class="container"
        >
            <div class="scroll" v-html="protocol" />
            <van-radio v-model="isChecked" @click="handleChange">
                我已阅读，并知晓上述内容
            </van-radio>
            <div class="button">
                <van-button type="info" @click="submit">
                    申请注销账户
                </van-button>
            </div>
        </section>
        <section v-else-if="+delFlag === 0 && +signFlag === 1">
            <div class="cancel">
                <img src="@/assets/images/wait.png" alt="等待注销" />
                <p>已提交注销申请，请等待结果</p>
            </div>
        </section>
        <section v-else>
            <div class="cancel">
                <img src="@/assets/images/delete.png" alt="账户已注销" />
                <p>账户已注销</p>
            </div>
        </section>
    </div>
</template>

<script>
import { Notify } from 'vant';
import { getStatus, cancelAccount } from '@/api';

export default {
    data() {
        return {
            loaded: false,
            protocol: '',
            delFlag: '', // 用户是否注销 0 未注销 1 已注销
            signFlag: '', // 签署状态  1:已签署  0:未签署
            agreementId: '',
            id: '',
            checked: false,
            isChecked: '',
        };
    },
    mounted() {
        const { id } = this.$route.query || {};
        this.id = id;
        this.checkStatus();
    },
    methods: {
        handleChange() {
            this.checked = true;
        },
        async checkStatus() {
            const { data } = await getStatus({ encryptId: this.id });
            if (data) {
                const { sourceAgreementId, delFlag, content, signFlag } = data;
                if (+delFlag === 0 && +signFlag === 0) {
                    this.agreementId = sourceAgreementId;
                    this.protocol = content;
                }
                this.delFlag = delFlag;
                this.signFlag = signFlag;
                // 测试待注销状态
                // this.delFlag = 0;
                // this.signFlag = 1;
                this.loaded = true;
            }
        },
        async submit() {
            if (!this.checked) {
                Notify({ type: 'warning', message: '请勾选同意账号注销协议' });
                return;
            }
            const { success } = await cancelAccount({
                encryptId: this.id,
                agreementId: this.agreementId,
            });
            if (success) {
                Notify({ type: 'success', message: '已提交申请' });
                this.signFlag = 1;
            }
            // 测试注销按钮
            // Notify({ type: 'success', message: '已提交申请' });
            // this.signFlag = 1;
        },
    },
};
</script>

<style lang="less" scoped>
.container {
    width: 88vw;
    margin: 0 auto;
    padding: 20px 0;
    line-height: 1.75;
    text-align: justify;
}
::-webkit-scrollbar {
    width: 0;
}
.scroll {
    height: calc(100vh - 130px);
    overflow: scroll;
}
.button {
    margin-top: 20px;
    text-align: center;
}
.cancel {
    width: 375px;
    margin: 0 auto;
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 100%;
    }
    p {
        color: rgba(144, 148, 158, 1);
        font-size: 15px;
        font-family: 'PingFangSC-Medium';
        white-space: nowrap;
        line-height: 20px;
        text-align: left;
    }
}
</style>
